.noSelect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
}
.wrapper {
  width: 100%;
  height: calc(100vh - 62px);
}
.mainPage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 0.15rem;
}
.pageTitle {
  font-weight: bold;
  font-size: 0.15rem;
  text-align: center;
  padding-bottom: 0.1rem;
  border-bottom: 1px solid #ddd;
}
.resultBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.resultItem {
  padding: 10px;
}
.content {
  margin-bottom: 0.15rem;
}
.contentTitle {
  padding: 0.05rem;
  display: flex;
  justify-content: space-between;
  background-color: #eee;
  align-items: center;
  font-weight: bold;
}
.innerListBox {
  display: flex;
  flex-direction: column;
  max-height: 1rem;
  overflow: auto;
}
.betweenFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.browserIcon {
  width: 18px;
  height: 18px;
  display: inline-block;
  background-size: 100% 100%;
}
.flexBox {
  display: flex;
  align-items: center;
  gap: 10px;
}
.flagsIcon {
  width: 24px;
  height: 18px;
  display: inline-block;
  background-size: 100% 100%;
}
