
.container {
    overflow: auto;
    padding: 0.15rem;
}

.resultBox{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }