@import "./theme.less";
.transition(@property: all, @duration: .3s,@timing-func: linear,@delay: 0s) {
  transition: @property @duration @timing-func @delay;
  -webkit-transition: @property @duration @timing-func @delay;
  -moz-transition: @property @duration @timing-func @delay;
  -o-transition: @property @duration @timing-func @delay;
  -ms-transition: @property @duration @timing-func @delay;
}

//用于tab固定首页的样式
.placeholder(@rules) {
  &::-webkit-input-placeholder {
    @rules();
  }
  &:-moz-placeholder { /* Firefox版本4-18 */
    @rules();
  }
  &::-moz-placeholder { /* Firefox版本19+ */
    @rules();
  }
  &:-ms-input-placeholder { /* IE浏览器 */
    @rules();
  }
}

.webkit_scrollbar() {
  //&::-webkit-scrollbar-track {
  //  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.6);
  //  background-color: #081943;
  //  border-radius: 10px;
  //}
  //&::-webkit-scrollbar {
  //  width: 8px;
  //  background-color: transparent;
  //}
  //&::-webkit-scrollbar-thumb {
  //  border-radius: 5px;
  //  background-color: #22234b;
  //}
}

.linear() {
  background-image: linear-gradient(90deg, rgba(102, 236, 251, .2) 0, rgba(19, 28, 49, 0) 98%);
}


.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}
